<template>
  <div class="brand-info">
    <div v-if="dataSeo?.content_title" class="brand-info__title" v-html="$sanitize(dataSeo?.content_title)" />
    <div
      v-if="dataSeo?.content_description"
      class="brand-info__description"
      v-html="$sanitize(dataSeo?.content_description)"
    />
    <div v-if="$device.isMobileOrTablet" class="brand-info__group-mobile">
      <div class="list-reserve">
        <a
          href="//www.dmca.com/Protection/Status.aspx?ID=f362511c-0d21-4b76-af37-f633d9a377ba"
          title="DMCA.com Protection Status"
          class="dmca-badge"
          target="_blank"
        >
          <CommonBaseImg src="/assets/images/dmca-mobile.webp" alt="DMCA.com Protection Status" />
        </a>
        <CommonBaseImg src="/assets/images/copyright-logo.webp" class="reserve-image" alt="copy right" />
      </div>
      <div class="copy-right">Copyright © 2024 Powered By {{ $config.public.BRANCH_NAME }} All Rights Reserved.</div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'

const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const { $device, $pinia } = useNuxtApp()
const route = useRoute()

const store = useAppStore($pinia)
const { seoData } = storeToRefs(store)
const dataSeo = computed(() => {
  if (route.path === '/') {
    return seoData.value.find((item) => item.alias === '/' || item.alias === '-')
  }
  if (route.name === 'khuyen-mai-id') {
    return seoData.value.find((item) => item.alias === 'khuyen-mai')
  }
  return seoData.value.find((item) => item.alias === route.path.slice(1)) ?? seoData.value.find((item) => item.alias === '/' || item.alias === '-')
})
</script>

<style scoped lang="scss" src="assets/scss/components/common/branch-info.scss" />
